import React, { useState } from "react";
// import { useFormik } from "formik";
import "./App.css";
import { Container } from "@mui/system";
import logo from "./assets/images/logo.png";
// import Start from "./parts/surveyScreens/start/start";
import ScreenOne from "./parts/surveyScreens/screen-one/screen-one";
import ButtonContainer from "./components/UI/button/button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import Fade from "@mui/material/Fade";
import data from "./data/data.json";
// import * as Yup from "yup";

const App = () => {
  const [screen, setScreen] = useState("What are you looking for?");
  const [service, setService] = useState("");
  const [planningToBuy, setPlanningToBuy] = useState("");
  const [propertyLocation, setPropertyLocation] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [propertyWorth, setPropertyWorth] = useState("");
  const [rateYourCredit, setRateYourCredit] = useState("");
  const [owingAmount, setOwingAmount] = useState("");
  const [amountLookingFor, setAmountLookingFor] = useState("");
  const [realtor, setRealtor] = useState("");
  const [contract, setContract] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [propertyOwned, setPropertyOwned] = useState("");
  const [occupiedBy, setOccupiedBy] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [homeTown, setHomeTown] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [spinner, setSpinner] = useState(false);

  const sendEmail = () => {
    setSpinner(true);

    const data = {
      service,
      realtor,
      contract,
      propertyWorth,
      downPayment,
      planningToBuy,
      propertyLocation,
      owingAmount,
      amountLookingFor,
      maritalStatus,
      propertyOwned,
      occupiedBy,
      rateYourCredit,
      fullName,
      email,
      phoneNumber,
      homeTown,
    };
    fetch("https://emailmmpquiz.herokuapp.com/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then(function (data) {
      setSpinner(false);
      setScreen("End");
    });
  };

  console.log(propertyWorth, downPayment, owingAmount, amountLookingFor);

  // const formik = useFormik({
  //   initialValues: {
  //     name: "",
  //     email: "",
  //     hometown: "",
  //     phone: "",
  //   },
  //   onSubmit: function (values) {
  //     setFullName(values.name);
  //     setEmail(values.email);
  //     setHomeTown(values.hometown);
  //     setPhoneNumber(values.phone);
  //   },
  //   validationSchema: Yup.object({
  //     name: Yup.string().required(),
  //     email: Yup.string().email().required(),
  //     hometown: Yup.string().required(),
  //     phone: Yup.number().required(),
  //   }),
  // });

  const nameHandler = (e) => {
    setFullName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const homeTownHandler = (e) => {
    setHomeTown(e.target.value);
  };

  const phoneNumberHandler = (e) => {
    setPhoneNumber(e.target.value);
  };

  return (
    <div className="mortGageContainer">
      <Container maxWidth="sm" className="mortgageBlock">
        <img src={logo} alt="Logo" />
        <h2>"ALTERNATIVE AND PRIVATE MORTGAGE LENDERS"</h2>
        <p>Our creative way to say YES , When your bank say NO</p>
        <div className="surveyContainer">
          {screen === "What are you looking for?" ? (
            <>
              <ScreenOne
                changeScreen={setScreen}
                screenTitle={data.whatAreYouLookingFor.title}
                surveyOptions={data.whatAreYouLookingFor.options}
                nextScreenOne={data.whatAreYouLookingFor.nextOne}
                nextScreenTwo={data.whatAreYouLookingFor.nextTwo}
                selected={setService}
              />
            </>
          ) : (
            ""
          )}
          {screen === "Where are you planning to buy?" ? (
            <>
              <ScreenOne
                changeScreen={setScreen}
                screenTitle={data.whereAreYouPlanningToBuy.title}
                nextScreen={data.whereAreYouPlanningToBuy.next}
                previousScreen={data.whereAreYouPlanningToBuy.previous}
                setPreviousScreen={true}
                selected={setPlanningToBuy}
                input={true}
                type={data.whereAreYouPlanningToBuy.inputType}
                name={data.whereAreYouPlanningToBuy.inputName}
              />
            </>
          ) : null}
          {screen === "Property Location" ? (
            <>
              <ScreenOne
                changeScreen={setScreen}
                screenTitle={data.propertyLocation.title}
                nextScreen={data.propertyLocation.next}
                previousScreen={data.propertyLocation.previous}
                setPreviousScreen={true}
                selected={setPropertyLocation}
                input={true}
                type={data.propertyLocation.inputType}
                name={data.propertyLocation.inputName}
              />
            </>
          ) : null}
          {screen === "Estimated value of the property" ? (
            <>
              <ScreenOne
                currency
                changeScreen={setScreen}
                screenTitle={
                  service === "Purchase"
                    ? data.estimatedValueOfTheProperty.title
                    : data.estimatedValueOfThePropertyTwo.title
                }
                nextScreen={
                  service === "Purchase"
                    ? data.estimatedValueOfTheProperty.next
                    : data.estimatedValueOfThePropertyTwo.next
                }
                previousScreen={
                  service === "Purchase"
                    ? data.estimatedValueOfTheProperty.previous
                    : data.estimatedValueOfThePropertyTwo.previous
                }
                setPreviousScreen={true}
                selected={setPropertyWorth}
                input={true}
                type={data.estimatedValueOfTheProperty.inputType}
                name={data.estimatedValueOfTheProperty.inputName}
              />
            </>
          ) : null}
          {screen === "How much down payment you have?" ? (
            <>
              <ScreenOne
                currency
                changeScreen={setScreen}
                screenTitle={data.howMuchDownPaymentDoYouHave.title}
                nextScreen={data.howMuchDownPaymentDoYouHave.next}
                previousScreen={data.howMuchDownPaymentDoYouHave.previous}
                setPreviousScreen={true}
                selected={setDownPayment}
                input={true}
                type={data.howMuchDownPaymentDoYouHave.inputType}
                name={data.howMuchDownPaymentDoYouHave.inputName}
              />
            </>
          ) : null}
          {screen === "Total amount owing on the property today" ? (
            <>
              <ScreenOne
                currency
                changeScreen={setScreen}
                screenTitle={data.totalAmountOwingOnThePropertyToday.title}
                nextScreen={data.totalAmountOwingOnThePropertyToday.next}
                previousScreen={
                  data.totalAmountOwingOnThePropertyToday.previous
                }
                setPreviousScreen={true}
                selected={setOwingAmount}
                input={true}
                type={data.totalAmountOwingOnThePropertyToday.inputType}
                name={data.totalAmountOwingOnThePropertyToday.inputName}
              />
            </>
          ) : null}
          {screen === "How much are you looking for?" ? (
            <>
              <ScreenOne
                currency
                changeScreen={setScreen}
                screenTitle={data.howMuchAreYouLookingFor.title}
                nextScreen={data.howMuchAreYouLookingFor.next}
                previousScreen={data.howMuchAreYouLookingFor.previous}
                setPreviousScreen={true}
                selected={setAmountLookingFor}
                input={true}
                type={data.howMuchAreYouLookingFor.inputType}
                name={data.howMuchAreYouLookingFor.inputName}
              />
            </>
          ) : null}
          {screen === "How do you rate your credit?" ? (
            <>
              <ScreenOne
                changeScreen={setScreen}
                screenTitle={data.howDoYouRateYourCredit.title}
                surveyOptions={data.howDoYouRateYourCredit.options}
                nextScreen={
                  service === "Purchase"
                    ? "Do you have a realtor?"
                    : "Marital Status"
                }
                previousScreen={
                  service === "Purchase"
                    ? "How much down payment you have?"
                    : "How much are you looking for?"
                }
                setPreviousScreen={true}
                selected={setRateYourCredit}
              />
            </>
          ) : (
            ""
          )}
          {screen === "Do you have a realtor?" ? (
            <>
              <ScreenOne
                changeScreen={setScreen}
                screenTitle={data.doYouHaveRealtor.title}
                surveyOptions={data.doYouHaveRealtor.options}
                nextScreen={data.doYouHaveRealtor.next}
                previousScreen={data.doYouHaveRealtor.previous}
                setPreviousScreen={true}
                selected={setRealtor}
              />
            </>
          ) : (
            ""
          )}
          {screen === "Do you have a contract yet?" ? (
            <>
              <ScreenOne
                changeScreen={setScreen}
                screenTitle={data.doYouHaveContract.title}
                surveyOptions={data.doYouHaveContract.options}
                nextScreen={data.doYouHaveContract.next}
                previousScreen={data.doYouHaveContract.previous}
                setPreviousScreen={true}
                selected={setContract}
              />
            </>
          ) : (
            ""
          )}
          {screen === "How much down payment do you have?" ? (
            <>
              <ScreenOne
                currency
                changeScreen={setScreen}
                screenTitle={data.howMuchDownPaymentDoYouHave.title}
                surveyOptions={data.howMuchDownPaymentDoYouHave.options}
                nextScreen={data.howMuchDownPaymentDoYouHave.next}
                previousScreen={data.howMuchDownPaymentDoYouHave.previous}
                setPreviousScreen={true}
                selected={setDownPayment}
              />
            </>
          ) : null}
          {screen === "Marital Status" ? (
            <>
              <ScreenOne
                changeScreen={setScreen}
                screenTitle={data.maritalStatus.title}
                surveyOptions={data.maritalStatus.options}
                nextScreen={data.maritalStatus.next}
                previousScreen={data.maritalStatus.previous}
                setPreviousScreen={true}
                selected={setMaritalStatus}
              />
            </>
          ) : (
            ""
          )}
          {screen === "How long owned property?" ? (
            <>
              <ScreenOne
                changeScreen={setScreen}
                screenTitle={data.howLongPropertyOwned.title}
                nextScreen={data.howLongPropertyOwned.next}
                previousScreen={data.howLongPropertyOwned.previous}
                setPreviousScreen={true}
                selected={setPropertyOwned}
                input={true}
                type={data.howLongPropertyOwned.inputType}
                name={data.howLongPropertyOwned.inputName}
              />
            </>
          ) : null}
          {screen === "Occupied by?" ? (
            <>
              <ScreenOne
                changeScreen={setScreen}
                screenTitle={data.occupiedBy.title}
                surveyOptions={data.occupiedBy.options}
                nextScreen={data.occupiedBy.next}
                previousScreen={data.occupiedBy.previous}
                setPreviousScreen={true}
                selected={setOccupiedBy}
              />
            </>
          ) : (
            ""
          )}
          {screen === "Contact Details" ? (
            <Fade in={true} timeout={2000}>
              <div className="contactContainer">
                <form>
                  <FormControl
                    sx={{ m: 1, width: "25ch" }}
                    variant="filled"
                    className="formField"
                  >
                    <InputLabel htmlFor="filled-adornment-password">
                      Full Name
                    </InputLabel>
                    <FilledInput
                      id="filled-adornment-password"
                      type={"text"}
                      onChange={nameHandler}
                      name={"name"}
                      required
                    />
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: "25ch" }}
                    variant="filled"
                    className="formField"
                  >
                    <InputLabel htmlFor="filled-adornment-password">
                      Email
                    </InputLabel>
                    <FilledInput
                      id="filled-adornment-password"
                      type={"email"}
                      onChange={emailHandler}
                      name={"email"}
                      required
                    />
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: "25ch" }}
                    variant="filled"
                    className="formField"
                  >
                    <InputLabel htmlFor="filled-adornment-password">
                      Hometown
                    </InputLabel>
                    <FilledInput
                      id="filled-adornment-password"
                      type={"text"}
                      onChange={homeTownHandler}
                      name={"hometown"}
                      required
                    />
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: "25ch" }}
                    variant="filled"
                    className="formField"
                  >
                    <InputLabel htmlFor="filled-adornment-password">
                      Phone number
                    </InputLabel>
                    <FilledInput
                      id="filled-adornment-password"
                      type={"text"}
                      onChange={phoneNumberHandler}
                      name={"phone"}
                      required
                    />
                  </FormControl>
                  <div className="disclaimer">
                  <p style={{color: 'red'}}>
                    *Must be a Valid Canadian Phone Number to receive
                    Pre-Qualification Information By submitting our email
                    information, we agree to receive promo and informational
                    material from MortgagePRO and or their affiliates by email.
                    Our consent can be withdrawn at any time by unsubscribing or
                    contacting MortgagePRO Ltd. office.
                  </p>
                </div>
                  <ButtonContainer
                    type="button"
                    text="Submit"
                    spinnerCheck={spinner}
                    sendEmail={sendEmail}
                    changeScreen={setScreen}
                  />
                </form>
              </div>
            </Fade>
          ) : (
            ""
          )}
          {screen === "End" ? (
            <div className="thankYouContainer">
              <h3 style={{ fontSize: "24px", fontWeight: "600" }}>
                Thank You!
              </h3>
              <p style={{ fontSize: "16px", color: "#000" }}>
                We will reach out to you within 24 hours to confirm your home
                loan solution and discuss your options. You can call or text us
                right now for a quick process or further inquiries.
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </Container>
    </div>
  );
};

export default App;
